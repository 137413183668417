h1{
    font-family: 'Roboto', sans-serif;
    letter-spacing: .1em;
    color:black;
    font-size: 2em;
    margin-top:100px;

}

.available-popup{
    color: red;
    font-size: 20pt;
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
}
.available-popup:hover{
background-color: peachpuff;
}

.corky-image{
    background-image: url('https://github.com/jokessenich/corky/blob/master/young-corky_BW_Retouched.jpg?raw=true');
    background-size:cover;
    height: 90vh;
    background-position: center;
}

.picture-caption{
    text-align:center;
    padding-bottom: 150px;
}

h2{
font-family: roboto;
    font-weight: 100;
    margin-bottom: 0px;
    font-size: 2em;
}

.infoBox{
    padding: 10%;
    font-family: roboto;
    padding-bottom: 50px;
    padding-top:5%;
}
p{
    letter-spacing: .05em;
    padding: 2%;
    text-align: left;
    line-height: 1.7em;
    font-family: roboto;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.about-book{
    font-size: 10pt;

}
.overlay{
height: 90vh;
background-color: #00000066;
}
.overlayText {
    color: white;
    padding-top: 400px;
    font-size: 14pt;
    margin-left:auto;
    margin-right:auto;
}

.emphasize-text{
    font-weight: bolder;
    font-size: 16pt;
}

.picDescriptionBox{
   display:flex;
   flex-direction:column;
}

.prefLink{
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-size: 14pt;
    color: black;
    
}

.italic{
    font-style: italic;
}

h4{
    margin-top: 0px;
}

.quote{
    margin-bottom: 0px;
}

.bookCoverDiv{
    padding-top:2%;
}
@media screen and (min-width: 800px){
    h1{
        font-size: 3em;
    }
    .overlayText {
    
        padding-top: 500px;
        font-size:16pt;
    }

    .picDescriptionBox{
    flex-direction: row;
    }
    .bookCoverDiv{
        padding-left:10%;
        padding-right:10%;
    }

    .bookImage{
        width: 300px;
        height: 450px;
    }
    .about-book{
        font-size:12pt;
        width:60%;
    }
}