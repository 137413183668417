.mediaList{
    flex-direction: column;
    text-align:left;
    text-decoration: none;;
}

a{
    text-decoration: none;
}

.photosList{
    flex-direction: column;
}

.MediaPhotosButton{
    color: #0000EE;

}

.MediaPhotosButton:hover{
    cursor: pointer;
}

@media screen and (min-width: 800px){

    .mediaList{
        margin-left: 40%;
    }
}