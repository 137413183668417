.footer{
    font-family:roboto;
    display: flex;
    justify-content: center;
    background-color: #c198d2;
    position:fixed;
    bottom:0px;
    width:100%;
    height: 100px;
}

    h3, h4{
        padding:20px;
        font-weight:100;
    }

    .facebook-icon{
        margin-top: 20px;
    }